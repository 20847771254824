import React from "react";
import styled from "styled-components";
import layout from "../../services/layout";
import { Icon } from "semantic-ui-react";
import Link from "next/link";

const Heading = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 15px;
  h2 {
    display: flex;
    margin: 0;
    white-space: nowrap;
    max-width: 100%;
    gap: 10px;
  }

  @media (max-width: ${layout.global.mobileMax}) {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-around;
  }
`

const SeeAll = styled.span`
  display: inline-block;

  @media (max-width: ${layout.global.smallMax}) {
    display: none;
  }
`

const HeadingText = styled.span`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`

function SectionHeadingLink({ href, children, hideSeeAll }) {
  return href ?
    <Link href={href}>
      <Heading>
        <h2><HeadingText>{children}</HeadingText> {!hideSeeAll && <Icon name='chevron right' />}</h2>
        {!hideSeeAll && <SeeAll>See all <Icon name='chevron right' /></SeeAll>}
      </Heading>
    </Link>
    : <Heading>
      <h2>{children}</h2>
    </Heading>
}

export default SectionHeadingLink;